import Condition from './Condition';
import { ConditionOperator } from './ConditionOperator';
import { LogicalOperator } from './LogicalOperator';

export default class Rule {
  contentControlId: string;
  ruleTitle: string;
  ruleTitleDisplay: string;
  includeListItem: boolean;
  conditions: Condition[];

  constructor() {
    this.contentControlId = '';
    this.ruleTitle = '';
    this.ruleTitleDisplay = '';
    this.includeListItem = false;
    this.conditions = [
      {
        dataKey: '',
        conditionValue: '',
        conditionOperator: ConditionOperator.Equal,
        logicalOperator: LogicalOperator.And,
      },
    ];
  }

  addCondition() {
    this.conditions.push({
      dataKey: '',
      conditionValue: '',
      conditionOperator: ConditionOperator.Equal,
      logicalOperator: LogicalOperator.And,
    });
  }

  removeCondition(index: number) {
    this.conditions.splice(index, 1);
  }
}
