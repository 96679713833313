import { Injectable } from '@angular/core';
import Flow from '../models/Flow';
import Question from '../models/Question';
import Step from '../models/Step';
import { WordService } from './word.service';
import CustomXmlPartContent from '../models/CustomXmlPartContent';

export interface FlowNode {
  label: string;
  object?: any;
  identifier?: string;
  type?: string;
  items?: FlowNode[];
  ruleIdentifier?: string;
  ruleLabel?: string;
  onClick?: (event?: CustomEvent) => void;
}

@Injectable({
  providedIn: 'root'
})
export class FlowTreeService {
  constructor(private _wordService: WordService) { }

  getFlowTree(flow: Flow): FlowNode[] {
    const tree: FlowNode[] = [
      {
        label: flow.title,
        items: this.getFlowNodes(flow)
      }
    ];
    return tree;
  }

  getFlowNodeByIndexes(indexes: string[], flowNodes: FlowNode[]): FlowNode | null {
    const currentNodeIndex = Number(indexes.shift());
    let currentNode = flowNodes[currentNodeIndex];

    if (indexes.length === 0) {
      return currentNode;
    }

    const nextIndex = Number(indexes.shift());
    if (currentNode.items && currentNode.items.length > nextIndex) {
      currentNode = currentNode.items[nextIndex];
      if (indexes.length === 0) {
        return currentNode;
      }
    }

    const nextFlowNodes = currentNode.items ?? [];

    return this.getFlowNodeByIndexes(indexes, nextFlowNodes);
  }

  getFlowNodeByRuleIdentifier(ruleIdentifier: string, flowNode: FlowNode): FlowNode | null {
    if (flowNode.ruleIdentifier && flowNode.ruleIdentifier === ruleIdentifier) {
      return flowNode;
    }
    
    if (flowNode.items) {
      for (const item of flowNode.items) {
        const foundNode = this.getFlowNodeByRuleIdentifier(ruleIdentifier, item);
        if (foundNode) {
          return foundNode;
        }
      }
    }

    return null;
  }

  async validateFlow(flowTree: FlowNode): Promise<boolean> {
    let valid = true;

    const identifierSuffixes: string[] = [
      ".assignto",
      ".steptitle",
      ".started",
      ".completed",
      ".stepdescription",
      ".comments",
      ".questioncode",
      ".questiontitle",
      ".questiondescription",
      ".questionanswer"
    ];
    const flowTreeIdentifiers: string[] = [];
    this.collectFlowNodeIdentifiers(flowTree, flowTreeIdentifiers);
    
    await Word.run(async (context) => {
      const { contentControls, customXmlParts } = await this._wordService.loadContentControlsAndCustomXmlParts(context);

      valid = await this.validateContentControls(contentControls, customXmlParts, context, valid, identifierSuffixes, flowTreeIdentifiers);
    });

    return valid;
  }

  private async validateContentControls(contentControls: Word.ContentControlCollection, customXmlParts: Word.CustomXmlPartCollection, context: Word.RequestContext, valid: boolean, identifierSuffixes: string[], flowTreeIdentifiers: string[]) {
    for (const contentControl of contentControls.items) {
      const tag = contentControl.tag;
      if (this.IsFFContentControl(tag)) {
        const customXmlPartContent = await this._wordService.getCustomXmlpartContent(customXmlParts, tag.replace('FF_', ''), context);

        valid = this.validateCustomXmlPart(identifierSuffixes, customXmlPartContent, flowTreeIdentifiers, contentControl, valid);
      }
    }
    return valid;
  }

  private validateCustomXmlPart(identifierSuffixes: string[], customXmlPartContent: CustomXmlPartContent, flowTreeIdentifiers: string[], contentControl: Word.ContentControl, valid: boolean) {
    for (const suffix of identifierSuffixes) {
      if (customXmlPartContent.key.endsWith(suffix)) {
        const identifier = customXmlPartContent.key.slice(0, customXmlPartContent.key.length - suffix.length);

        if (!flowTreeIdentifiers.includes(identifier)) {
          this._wordService.markContentControlAsInvalid(contentControl);
          valid = false;
        }

        break;
      }
    }
    return valid;
  }

  private IsFFContentControl(tag: string) {
    return tag.startsWith('FF_') && !tag.startsWith('FF_Table') && !tag.startsWith('FF_Section') && !tag.startsWith('FF_Rule');
  }

  private collectFlowNodeIdentifiers(flowNode: FlowNode, identifiers: string[]) {
    if (flowNode.identifier) {
      identifiers.push(flowNode.identifier)
    }

    if (flowNode.items) {
      for (const item of flowNode.items) {
        this.collectFlowNodeIdentifiers(item, identifiers);
      }
    }
  }

  private getFlowNodes(flow: Flow): FlowNode[] {
    const branch: FlowNode[] = [];
    branch.push({ label: "Completed", onClick: () => this._wordService.addDictionaryKeyContentControl("Placeholder for Completed", "Completed", "completed"), ruleIdentifier: "completed", ruleLabel: "Completed" },);
    branch.push({ label: "Started", onClick: () => this._wordService.addDictionaryKeyContentControl("Placeholder for Started", "Started", "started"), ruleIdentifier: "started", ruleLabel: "Started"  });
    branch.push({ label: "Title", onClick: () => this._wordService.addDictionaryKeyContentControl("Placeholder for Title", "Title", "title"), ruleIdentifier: "title", ruleLabel: "Title"  });
    branch.push({ label: "Form Id", onClick: () => this._wordService.addDictionaryKeyContentControl("Placeholder for Form Id", "Form Id", "id"), ruleIdentifier: "id", ruleLabel: "Form Id"  });
    branch.push({ label: "Steps", items: this.getFlowSteps(flow) });
    return branch;
  }

  private getFlowSteps(flow: Flow): FlowNode[] {
    const branch: FlowNode[] = [];
    flow.steps?.forEach(step => {
      const identifier = step.stepCode.replaceAll('.', '{FF_DOT}');
      const node: FlowNode = {
        label: step.title,
        identifier: identifier
      };

      if (step.isParallelGroup) {
        node.items = this.getParallelSteps(step, identifier);
      } else {
        node.items = this.getStepNodes(step, identifier, "");
      }

      branch.push(node);
    });

    return branch;
  }

  private getParallelSteps(step: Step, identifier: string): FlowNode[] {
    const branch: FlowNode[] = [];
    const ruleLabelIdentifier = `${step.title} - `;
    step.steps?.forEach(parallelStep => {
      const nextIdentifier = `${identifier}.${parallelStep.stepCode.replaceAll('.', '{FF_DOT}')}`;
      const node: FlowNode = {
        label: parallelStep.title,
        identifier: nextIdentifier,
        items: this.getStepNodes(parallelStep, nextIdentifier, ruleLabelIdentifier),
      }
      branch.push(node)
    })
    return branch;
  }

  private getStepNodes(step: Step, identifier: string, ruleLabelIdentifier: string): FlowNode[] {
    const branch: FlowNode[] = [];
    ruleLabelIdentifier += `${step.title} - `;
    branch.push({ label: "Assigned to", identifier: identifier, onClick: () => this._wordService.addDictionaryKeyContentControl("Placeholder for Assigned to", `${step.title} Assigned to`, `${identifier}.assignto`), ruleIdentifier: `${identifier}.assignto`, ruleLabel: `${ruleLabelIdentifier}Assigned to` });
    branch.push({ label: "Title", identifier: identifier, onClick: () => this._wordService.addDictionaryKeyContentControl("Placeholder for Title", `${step.title} Title`, `${identifier}.steptitle`), ruleIdentifier: `${identifier}.steptitle`, ruleLabel: `${ruleLabelIdentifier}Title` });
    branch.push({ label: "Started", identifier: identifier, onClick: () => this._wordService.addDictionaryKeyContentControl("Placeholder for Started", `${step.title} Started`, `${identifier}.started`), ruleIdentifier: `${identifier}.started`, ruleLabel: `${ruleLabelIdentifier}Started` });
    branch.push({ label: "Completed", identifier: identifier, onClick: () => this._wordService.addDictionaryKeyContentControl("Placeholder for Completed", `${step.title} Completed`, `${identifier}.completed`), ruleIdentifier: `${identifier}.completed`, ruleLabel: `${ruleLabelIdentifier}Completed` });
    branch.push({ label: "Description", identifier: identifier, onClick: () => this._wordService.addDictionaryKeyContentControl("Placeholder for Description", `${step.title} Description`, `${identifier}.stepdescription`), ruleIdentifier: `${identifier}.stepdescription`, ruleLabel: `${ruleLabelIdentifier}Description` });
    branch.push({ label: "Comments", identifier: identifier, onClick: () => this._wordService.addDictionaryKeyContentControl("Placeholder for Comments", `${step.title} Comments`, `${identifier}.comments`), ruleIdentifier: `${identifier}.comments`, ruleLabel: `${ruleLabelIdentifier}Comments` });
    branch.push({ label: "Questions", items: this.getStepQuestions(step, identifier, ruleLabelIdentifier) });
    return branch;
  }

  private getStepQuestions(step: Step, identifier: string, ruleLabelIdentifier: string): FlowNode[] {
    const branch: FlowNode[] = [];
    step.questions?.forEach(question => {
      const node: FlowNode = this.getQuestionNode(question, identifier, ruleLabelIdentifier);
      branch.push(node);
    });
    return branch;
  }

  private getQuestionNodes(question: Question, identifier: string, ruleLabelIdentifier: string): FlowNode[] {
    const branch: FlowNode[] = [];

    branch.push({ label: "Question code", identifier: identifier, onClick: () => this._wordService.addDictionaryKeyContentControl("Placeholder for Question code", `${question.title} Question code`, `${identifier}.questioncode`), ruleIdentifier: `${identifier}.questionode`, ruleLabel: `${ruleLabelIdentifier}Question code` });
    branch.push({ label: "Title", identifier: identifier, onClick: () => this._wordService.addDictionaryKeyContentControl("Placeholder for Title", `${question.title} Title`, `${identifier}.questiontitle`), ruleIdentifier: `${identifier}.questiontitle`, ruleLabel: `${ruleLabelIdentifier}Title` });
    branch.push({ label: "Description", identifier: identifier, onClick: () => this._wordService.addDictionaryKeyContentControl("Placeholder for Description", `${question.title} Description`, `${identifier}.questiondescription`), ruleIdentifier: `${identifier}.questiondescription`, ruleLabel: `${ruleLabelIdentifier}Description` });

    const answerNode: FlowNode = {
      label: "Answer",
      object: question,
      identifier: identifier,
      type: question.type,
      onClick: () => {
        this._wordService.addDictionaryKeyContentControl("Placeholder for Answer", `${question.title} Answer`, `${identifier}.questionanswer`)
      },
      ruleIdentifier: `${identifier}.questionanswer`,
      ruleLabel: `${ruleLabelIdentifier}Answer`
    };
    if (answerNode.type === "repeatingtable") {
      answerNode.onClick = undefined;
    }
    branch.push(answerNode);

    if (question.questions && question.questions.length > 0) {
      branch.push({
        label: "Subquestions",
        items: this.getSubQuestions(question, identifier, ruleLabelIdentifier)
      });
    }
    return branch;
  }

  private getSubQuestions(question: Question, identifier: string, ruleLabelIdentifier: string): FlowNode[] {
    const branch: FlowNode[] = [];
    question.questions.forEach(subQuestion => {
      const node: FlowNode = this.getQuestionNode(subQuestion, identifier, ruleLabelIdentifier);
      branch.push(node);
    });
    return branch;
  }

  private getQuestionNode(question: Question, identifier: string, ruleLabelIdentifier: string): FlowNode {
    const nextIdentifier = `${identifier}.${question.questionCode.replaceAll('.', '{FF_DOT}')}`;
    const nextRuleLabelIndentifier = `${ruleLabelIdentifier}${question.title} - `
    return {
      label: question.title,
      identifier: nextIdentifier,
      items: this.getQuestionNodes(question, nextIdentifier, nextRuleLabelIndentifier)
    };
  }
}