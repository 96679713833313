import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html'
})
export class GetStartedComponent {
  @Output() getStartedEmit = new EventEmitter();

  getStarted(): void {
    this.getStartedEmit.emit();
  }
}
