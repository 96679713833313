<div class="container-fluid m-3" *ngIf="rule">
    <div class="mb-3 row">
        <label for="ruleTitle" class="col-2 col-form-label">Rule title</label>
        <div class="col-10">
            <input type="text" [(ngModel)]="rule.ruleTitle" class="form-control" id="ruleTitle">
        </div>
    </div>
    <div class="container">
        <div class="row">
            <p>Display the selected section when this condition is true:</p>
        </div>
        <ng-container *ngIf="flowTree">
            <div *ngFor="let condition of rule.conditions; let i=index" class="row mb-3">
                <app-rule-condition [flowTree]="flowTree" [condition]="condition" [showAddButton]="i+1 === rule.conditions.length" [showRemoveButton]="rule.conditions.length > 1" (addEmit)="addCondition()" (removeEmit)="removeCondition(i)"></app-rule-condition>
            </div>
        </ng-container>
        <div class="row justify-content-end">
            <div class="col-8">
                <input [(ngModel)]="rule.includeListItem" class="form-check-input m-0 mx-1" type="checkbox" id="includeListItem">
                <label class="form-check-label" for="includeListItem">Include whole list item</label>
            </div>
            <div class="col-2">
                <button type="button" (click)="saveRule()" class="btn btn-primary height w-100">Ok</button>
            </div>
            <div class="col-2">
                <button type="button" (click)="close()" class="btn btn-danger height w-100">Cancel</button>
            </div>
        </div>
    </div>
</div>
