import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WordService } from './word.service';

@Injectable({
  providedIn: 'root'
})
export class FlowService {
  private _domain: string = environment.ffApiUrl;
  private _appUrl: string;
  private _flowId: string;

  constructor(private _httpClient: HttpClient, private _wordService: WordService) {
  }

  getFlow(): Promise<Observable<any>> {
    return this.loadProperties().then(() =>
      this._httpClient.post(`${this._domain}/api/DocumentGeneration/GetFlow`, JSON.stringify({ appUrl: this._appUrl, id: this._flowId, token: localStorage.getItem("token") }), {
        headers: {
          'Content-Type': 'application/json'
        }
      })
    );
  }

  private async loadProperties(): Promise<void> {
    const appUrl = await this._wordService.getProperty("_AppUrl");
    const flowId = await this._wordService.getProperty("_FlowId");
    this._appUrl = appUrl!;
    this._flowId = flowId!;
  }
}
