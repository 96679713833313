import { Component, Input, OnInit } from '@angular/core';
import Flow from 'src/app/models/Flow';
import Rule from 'src/app/models/rules/Rule';
import RuleBuilderDialogContent from 'src/app/models/rules/RuleBuilderDialogContent';
import { WordService } from 'src/app/services/word.service';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss'],
})
export class RulesComponent implements OnInit {
  @Input() flow: Flow;
  rules: Rule[] = [];
  private _ruleBuilderDialog: Office.Dialog;

  constructor(private _wordService: WordService) { }

  async ngOnInit() {
    await this.refreshRules();
  }

  async highlight(rule: Rule) {
    await this._wordService.highlightRuleContentControl(rule);
  }

  async remove(rule: Rule) {
    this.rules = this.rules.filter(r => r.contentControlId !== rule.contentControlId);
    await this._wordService.updateRules(this.rules);
    await this._wordService.removeRuleContentControl(rule);
  }

  async refreshRules() {
    this.rules = await this._wordService.getRules();
  }

  async openRuleBuilder(newRule: boolean, rule?: Rule): Promise<void> {
    return new Promise<void>((resolve) => {
      Office.context.ui.displayDialogAsync(
        `${window.location.origin}/rule`,
        { height: 40, width: 60 },
        (asyncResult) => {
          this._ruleBuilderDialog = asyncResult.value;
          this._ruleBuilderDialog.addEventHandler(
            Office.EventType.DialogMessageReceived,
            async (arg: any) => {
              switch (arg.message) {
                case 'ready':
                  {
                    const ruleBuilderDialogContent: RuleBuilderDialogContent = {
                      flow: this.flow,
                      rule: rule,
                    };
                    this._ruleBuilderDialog.messageChild(
                      JSON.stringify(ruleBuilderDialogContent)
                    );
                    break;
                  }
                case 'close':
                  this._ruleBuilderDialog.close();
                  resolve();
                  break;
                default:
                  rule = JSON.parse(arg.message) as Rule;
                  if (newRule) {
                    await this.addRule(rule);
                  } else {
                    await this.updateRule(rule);
                  }
                  this._ruleBuilderDialog.close();
                  resolve();
                  break;
              }
            }
          );
        }
      );
    });
  }

  private async addRule(rule: Rule) {
    await this._wordService.addRuleContentControl(rule);
    this.rules = await this._wordService.getRules();
    this.rules.push(rule);
    await this._wordService.updateRules(this.rules);
  }

  private async updateRule(rule: Rule) {
    this.rules = this.rules.map(r => {
      if (r.contentControlId === rule.contentControlId) {
        return rule;
      }
      return r;
    });
    await this._wordService.updateRules(this.rules);
  }
}
