<div class="container">
    <div class="row">
        <div class="col-3">
            <smart-tree class="w-100" #tree id="tree" (onChange)="handleOnChange($event)" selectionMode="one" [showLines]="true" scrollMode="scrollbar"></smart-tree>
        </div>
        <div class="col-2">
            <select (change)="changeConditionOperator($event)" class="form-select height" aria-label="Default select example">
                <option *ngFor="let conditionOperator of conditionOperatorOptions" [value]="conditionOperator.value" [selected]="conditionOperator.value === condition.conditionOperator">{{ conditionOperator.label }}</option>
            </select>
        </div>
        <div class="col-3">
            <input type="text" [(ngModel)]="condition.conditionValue" class="form-control height" id="conditionValue">
        </div>
        <div *ngIf="showAddButton" class="col-2">
            <button class="btn btn-primary height w-100" (click)="add()" >add</button>
        </div>
        <ng-container *ngIf="showRemoveButton">
            <div class="col-2" *ngIf="!showAddButton">
                <select (change)="changeLogicalOperator($event)" class="form-select height" aria-label="Default select example">
                    <option *ngFor="let logicalOperator of logicalOperatorOptions" [value]="logicalOperator.value" [selected]="logicalOperator.value === condition.logicalOperator">{{ logicalOperator.label }}</option>
                </select>
            </div>
            <div class="col-2">
                <button class="btn btn-danger height w-100" (click)="remove()" >remove</button>
            </div>
        </ng-container>
    </div>
</div>