import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-commands',
  template: ''
})
export class CommandsComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document, private _authService: AuthService) {
  }

  async logoutFlowFormaAddin(event: any) {
    await this._authService.logoutUser();

    event.completed();
  }

  ngOnInit() {
    (<any>this.document).defaultView['logoutFlowFormaAddin'] = this.logoutFlowFormaAddin.bind(this);
  }
}