import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TreeModule } from 'smart-webcomponents-angular/tree';
import { MenuModule } from 'smart-webcomponents-angular/menu';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { TaskpaneComponent } from './components/taskpane/taskpane.component';
import { FlowComponent } from './components/flow/flow.component';
import { RulesComponent } from './components/rules/rules.component';
import { CommandsComponent } from './components/commands/commands.component';
import { LogoutComponent } from './components/logout/logout.component';
import { RuleBuilderComponent } from './components/rule-builder/rule-builder.component';
import { RuleConditionComponent } from './components/rule-condition/rule-condition.component';
import { FormsModule } from '@angular/forms';
import { GetStartedComponent } from './components/get-started/get-started.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TaskpaneComponent,
    FlowComponent,
    RulesComponent,
    CommandsComponent,
    LogoutComponent,
    RulesComponent,
    RuleBuilderComponent,
    RuleConditionComponent,
    GetStartedComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TreeModule,
    MenuModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
