<div>
    <button class="btn btn-primary btn-sm w-100 mt-1" [disabled]="validationLoading" (click)="validate()">
        <span *ngIf="validationLoading" class="spinner-border spinner-border-sm"></span>
        Validate
    </button>
    <ng-container *ngIf="showValidationStatus">
        <div [ngClass]="valid ? 'alert alert-success alert-dismissible' : 'alert alert-danger alert-dismissible'"
            role="alert">
            <ng-container [ngSwitch]="valid">
                <strong *ngSwitchCase="true">Validation successful</strong>
                <strong *ngSwitchCase="false">Validation unsuccessful</strong>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"
                    (click)="closeValidationStatus()"></button>
            </ng-container>
        </div>
    </ng-container>
</div>
<smart-tree #tree id="tree" [unfocusable]="true" (onChange)="handleOnChange($event)" scrollMode="scrollbar" selectionMode="zeroOrOne"
    [showLines]="true"></smart-tree>
<smart-menu #menu [mode]="'dropDown'" [closeAction]="'down'">
    <smart-menu-item [value]="'repeatingTable'">Repeating Table</smart-menu-item>
    <smart-menu-item [value]="'repeatingSection'">Repeating Section</smart-menu-item>
</smart-menu>