import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TreeComponent } from 'smart-webcomponents-angular/tree';
import Condition from 'src/app/models/rules/Condition';
import { ConditionOperator } from 'src/app/models/rules/ConditionOperator';
import { LogicalOperator } from 'src/app/models/rules/LogicalOperator';
import { FlowNode, FlowTreeService } from 'src/app/services/flow-tree.service';

@Component({
  selector: 'app-rule-condition',
  templateUrl: './rule-condition.component.html',
  styleUrls: ['./rule-condition.component.scss'],
})
export class RuleConditionComponent implements OnInit, AfterViewInit {
  @Input() condition: Condition;
  @Input() flowTree: FlowNode[];
  @Input() showAddButton: boolean;
  @Input() showRemoveButton: boolean;

  @Output() addEmit = new EventEmitter();
  @Output() removeEmit = new EventEmitter();

  @ViewChild('tree', { read: TreeComponent, static: false })
  tree!: TreeComponent;

  conditionOperatorOptions: { value: ConditionOperator; label: string; }[];
  logicalOperatorOptions: { value: LogicalOperator; label: string; }[];

  constructor(private _flowTreeService: FlowTreeService) { }

  ngOnInit(): void {
    this.conditionOperatorOptions = Object.values(ConditionOperator)
      .filter((key) => !isNaN(Number(key)))
      .map((key) => ({ value: key as ConditionOperator, label: ConditionOperator[Number(key)]}));
    this.logicalOperatorOptions = Object.values(LogicalOperator)
      .filter((key) => !isNaN(Number(key)))
      .map((key) => ({ value: key as LogicalOperator, label: LogicalOperator[Number(key)]}));
  }

  ngAfterViewInit(): void {
      this.tree.dataSource = this.flowTree;

      const flowNode = this._flowTreeService.getFlowNodeByRuleIdentifier(this.condition.dataKey, this.flowTree[0]);
      if (flowNode) {
        this.selectConditionDataKey(flowNode);
      } else {
        this.tree.selectedIndexes = ['0.0'];
      }
  }

  add() {
    this.addEmit.emit();
  }

  remove() {
    this.removeEmit.emit();
  }

  changeConditionOperator(event: Event) {
    const target = event.target as HTMLTextAreaElement;
    const value = Number(target.value) as ConditionOperator;
    this.condition.conditionOperator = value;
  }

  changeLogicalOperator(event: Event) {
    const target = event.target as HTMLTextAreaElement;
    const value = Number(target.value) as LogicalOperator;
    this.condition.logicalOperator = value;
  }

  handleOnChange(event: CustomEvent) {
    const detail = event.detail;
    const indexes =
      detail.selectedIndexes.length !== 0
        ? detail.selectedIndexes[0].split('.')
        : detail.oldSelectedIndexes[0].split('.');

    const flowNode = this._flowTreeService.getFlowNodeByIndexes(
      indexes,
      this.flowTree
    );

    if (flowNode?.ruleIdentifier) {
      this.condition.dataKey = flowNode.ruleIdentifier;
      this.tree.collapseAll();
      this.selectConditionDataKey(flowNode);
      this.tree.selectedIndexes = ['0'];
    }
  }

  private selectConditionDataKey(flowNode: FlowNode) {
    setTimeout(() => {
      const element = this.tree.nativeElement.querySelector(".smart-tree-item-label-element") as HTMLElement;
      const spanElement = element.querySelector("span") as HTMLElement;
      spanElement.textContent = flowNode.ruleLabel as string;
      if (spanElement.offsetWidth + 1 > element.getBoundingClientRect().width) {
        element.style.direction = "rtl";
      } else {
        element.style.direction = "ltr";
      }
    }, 100);
  }
}
