<div class="container d-flex flex-column">
  <div class="table-responsive mt-3 flex-grow-1">
      <table aria-describedby="rulesTable" class="table table-striped table-hover w-100">
        <thead>
          <tr>
            <th class="list-header header-container ms-3">Rule</th>
            <th class="text-right"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let rule of rules; let i = index">
            <td (click)="openRuleBuilder(false, rule)">
              <a class="text-decoration-none text-body rule-link">Rule {{i+1}}: {{ rule.ruleTitle }}</a>
            </td>
            <td class="d-flex justify-content-around">
              <a class="text-primary search" title="Settings" data-toggle="tooltip" (click)="highlight(rule)">
                <i class="material-icons">&#xE8B6;</i>
              </a>
              <a class="text-danger delete" title="Delete" data-toggle="tooltip" (click)="remove(rule)">
                <i class="material-icons">clear</i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
  </div>
  <div class="d-flex p-3">
    <button class="btn btn-primary mx-2 w-100" (click)="openRuleBuilder(true)">Add Rule</button>
    <button class="btn btn-secondary mx-2 w-100" (click)="refreshRules()">Refresh Rules</button>
  </div>
</div>