import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MenuComponent } from 'smart-webcomponents-angular/menu';
import { TreeComponent } from 'smart-webcomponents-angular/tree';
import Flow from 'src/app/models/Flow';
import { FlowNode, FlowTreeService } from 'src/app/services/flow-tree.service';
import { WordService } from 'src/app/services/word.service';

@Component({
  selector: 'app-flow',
  templateUrl: './flow.component.html',
  styleUrls: ['./flow.component.scss'],
})
export class FlowComponent implements AfterViewInit {
  @Input() flow: Flow;

  @ViewChild('tree', { read: TreeComponent, static: false })
  tree!: TreeComponent;
  @ViewChild('menu', { read: MenuComponent, static: false })
  menu!: MenuComponent;
  private _flowTree: FlowNode[];
  private _contextMenuNode: FlowNode | null;
  private _contextParentNode: FlowNode | null;
  valid = false;
  showValidationStatus = false;
  validationLoading = false;

  constructor(
    private _flowTreeService: FlowTreeService,
    private _wordService: WordService
  ) {}

  ngAfterViewInit(): void {
    this._flowTree = this._flowTreeService.getFlowTree(this.flow);
    this.tree.dataSource = this._flowTree;

    this.addTreeContextMenuListener();
  }

  handleOnChange(event: CustomEvent) {
    const detail = event.detail;
    const indexes =
      detail.selectedIndexes.length !== 0
        ? detail.selectedIndexes[0].split('.')
        : detail.oldSelectedIndexes[0].split('.');

    const flowNode = this._flowTreeService.getFlowNodeByIndexes(
      indexes,
      this._flowTree
    );

    if (flowNode?.onClick) {
      flowNode.onClick();
    }
  }

  async validate() {
    this.closeValidationStatus();
    this.validationLoading = true;
    this.valid = await this._flowTreeService.validateFlow(this._flowTree[0]);
    this.showValidationStatus = true;
    this.validationLoading = false;
    setTimeout(() => {
      this.closeValidationStatus();
    }, 3000);
  }

  closeValidationStatus() {
    this.showValidationStatus = false;
  }

  private addTreeContextMenuListener() {
    this.tree.addEventListener('contextmenu', (event: any) =>
      this.contextMenuEventListener(event)
    );
    this.menu.addEventListener('itemClick', (event: any) =>
      this.itemClickEventListener(event)
    );
  }

  private itemClickEventListener(event: any) {
    event = event as CustomEvent;
    const eventDetail = event.detail;
    const methodName = eventDetail.value;

    switch (methodName) {
      case 'repeatingTable':
        this._wordService.drawRepeatingTableContentControl(
          this._contextMenuNode!.object,
          this._contextParentNode!
        ).then().catch(error => console.log(error));
        break;
      case 'repeatingSection':
        this._wordService.drawRepeatingSectionContentControl(
          this._contextMenuNode!.object,
          this._contextParentNode!
        ).then().catch(error => console.log(error));
        break;
    }
  }

  private contextMenuEventListener(event: any) {
    event.preventDefault();

    const target = event.target as HTMLElement;
    const item = target.closest('smart-tree-item');

    if (!item) {
      return;
    }

    const indexes = this.parseIndexes(item.id);
    this._contextMenuNode = this._flowTreeService.getFlowNodeByIndexes(
      indexes.slice(),
      this._flowTree
    );
    this._contextParentNode = this._flowTreeService.getFlowNodeByIndexes(
      indexes.slice(0, -1),
      this._flowTree
    );

    if (!this._contextMenuNode) return;

    if (
      this._contextMenuNode.label === 'Answer' &&
      this._contextMenuNode.type &&
      this._contextMenuNode.type === 'repeatingtable' &&
      Office.context.platform !== Office.PlatformType.OfficeOnline
    ) {
      this.menu.open(event.pageX, event.pageY);
    } else {
      return;
    }
  }

  private parseIndexes(str: string): string[] {
    str = str.substring('treeItemP'.length).replace(/L.*/, '');
    const parts = str.split('_');

    const indexes: string[] = [];

    parts.forEach((part) => {
      indexes.push(part);
    });

    return indexes;
  }
}
