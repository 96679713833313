<ng-container *ngIf="flow && isValidDocumentTemplate">
  <ul *ngIf="activeTab !== ''" class="nav nav-pills nav-fill bg-white p-3 rounded">
    <li class="nav-item mx-2">
      <a #flowTab class="nav-link fw-bold rounded px-4 py-2 active" aria-current="page"
        (click)="setActiveTab($event, 'flow')">Flow</a>
    </li>
    <li class="nav-item mx-2">
      <a #rulesTab class="nav-link fw-bold rounded px-4 py-2" (click)="setActiveTab($event, 'rules')">Rules</a>
    </li>
  </ul>
  <div [ngSwitch]="activeTab" class="h-100 overflow-auto">
    <app-flow *ngSwitchCase="'flow'" [flow]="flow"></app-flow>
    <app-rules *ngSwitchCase="'rules'" [flow]="flow"></app-rules>
    <app-get-started *ngSwitchDefault (getStartedEmit)="getStarted()"></app-get-started>
  </div>
</ng-container>
<ng-container *ngIf="!isValidDocumentTemplate">
  <div class="container my-2">
    <div class="card p-3">
      <div class="card-header">
        This template is not compatible with the FlowForma Add-in. 
      </div>
      <div class="card-body">
        <p class="card-text">
          Please check the help file for further details.
        </p>
      </div>
    </div>
  </div>
</ng-container>