import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import Flow from 'src/app/models/Flow';
import { AuthService } from 'src/app/services/auth.service';
import { FlowService } from 'src/app/services/flow.service';
import { WordService } from 'src/app/services/word.service';

@Component({
  selector: 'app-taskpane',
  templateUrl: './taskpane.component.html',
  styleUrls: ['./taskpane.component.scss'],
})
export class TaskpaneComponent implements OnInit, OnDestroy {
  activeTab = '';
  flow?: Flow;
  isValidDocumentTemplate = true;

  
  @ViewChild('flowTab', { static: false }) flowTab!: ElementRef;
  @ViewChild('rulesTab', { static: false }) rulesTab!: ElementRef;

  constructor(private _authService: AuthService,private _flowService: FlowService, private _wordService: WordService) {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  async init(): Promise<void> {
    this.isValidDocumentTemplate = await this._wordService.IsValidDocumentTemplate();
    if (this.isValidDocumentTemplate) {
      await this._authService.authenticateUser();
      const observableFlow = await this._flowService.getFlow();
      observableFlow.subscribe((flow: Flow) => {
        this.flow = flow;
      });
    }
  }

  setActiveTab(event: Event, tabName: string): void {
    event.preventDefault();
    this.activeTab = tabName;
    this.flowTab.nativeElement.classList.toggle('active', tabName === 'flow');
    this.rulesTab.nativeElement.classList.toggle('active', tabName === 'rules');
  }

  async ngOnInit() {
    await this.init();
  }

  getStarted(): void {
    this.activeTab = 'flow';
  }

  ngOnDestroy(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage) {
      if (event.key == 'token' && event.newValue == null) {
        this.flow = undefined;
      }
    }
  }
}
