import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import Rule from 'src/app/models/rules/Rule';
import RuleBuilderDialogContent from 'src/app/models/rules/RuleBuilderDialogContent';
import { FlowNode, FlowTreeService } from 'src/app/services/flow-tree.service';

@Component({
  selector: 'app-rule-builder',
  templateUrl: './rule-builder.component.html',
  styleUrls: ['./rule-builder.component.scss'],
})
export class RuleBuilderComponent implements OnInit {
  rule?: Rule;
  flowTree: FlowNode[];

  constructor(private _flowTreeService: FlowTreeService, private _cdr: ChangeDetectorRef) { }

  async ngOnInit() {
    Office.context.ui.messageParent("ready");
    Office.context.ui.addHandlerAsync(
      Office.EventType.DialogParentMessageReceived,
      (arg) => {
        this.initializeRuleBuilder(arg);
      }
    );
  }

  private initializeRuleBuilder(arg: Office.DialogParentMessageReceivedEventArgs) {
    const ruleBuilderDialogContent = JSON.parse(arg.message) as RuleBuilderDialogContent;
    this.flowTree = this._flowTreeService.getFlowTree(ruleBuilderDialogContent.flow);
    this.rule = ruleBuilderDialogContent.rule === undefined ? new Rule() : Object.assign(new Rule(), ruleBuilderDialogContent.rule);
    this._cdr.detectChanges();
  }

  saveRule() {
    Office.context.ui.messageParent(JSON.stringify(this.rule));
  }

  addCondition() {
    this.rule?.addCondition();
    this._cdr.detectChanges();
  }

  removeCondition(index: number) {
    this.rule?.removeCondition(index);
    this._cdr.detectChanges();
  }

  close() {
    Office.context.ui.messageParent('close');
  }
}
