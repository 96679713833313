import { LogoutComponent } from './components/logout/logout.component';
import { CommandsComponent } from './components/commands/commands.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { TaskpaneComponent } from './components/taskpane/taskpane.component';
import { RuleBuilderComponent } from './components/rule-builder/rule-builder.component';

const routes: Routes = [
  { path: 'taskpane', component: TaskpaneComponent },
  { path: 'login', component: LoginComponent },
  { path: 'commands', component: CommandsComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'rule', component: RuleBuilderComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
