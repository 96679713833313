import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  private _tenantUrl: string;
  private _tenantName: string;

  private _token: BehaviorSubject<string | null>;
  private token$: Observable<string | null>;

  constructor(private route: ActivatedRoute) {
    this._token = new BehaviorSubject<string | null>(null);
    this.token$ = this._token.asObservable();

    this.token$.subscribe(token => {
      if (token) {
        Office.context.ui.messageParent(token);
      }
    });
  }

  ngOnInit(): void {
    const params = new URLSearchParams(window.location.hash.substring(1));
    const accessToken = params.get("access_token");
    if (accessToken) {
      this._token.next(accessToken);
    }
    else {
      this.getQueryParameters();
      this.authenticate();
    }
  }

  private getQueryParameters(): void {
    const params = this.route.snapshot.queryParams;
    this._tenantUrl = params['tenantUrl'];
    this._tenantName = params['tenantName'];
  }

  private authenticate() {
    const url = `https://login.microsoftonline.com/${this._tenantName}/oauth2/authorize?client_id=${environment.msal.cliendId}&response_type=token&redirect_uri=${window.location.origin}/login&resource=${this._tenantUrl}`
    window.location.href = url;
  }
}
