import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-logout',
  template: ''
})
export class LogoutComponent implements OnInit {

  constructor(private _route: ActivatedRoute) { }

  ngOnInit(): void {
    const loggedOut = this._route.snapshot.queryParams['loggedOut'];
    
    if (loggedOut) {
      Office.context.ui.messageParent("Logged out");
    }
    else {
      const logoutUrl = `https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=${window.location.origin}/logout?loggedOut=true`;
      window.location.href = logoutUrl;
    }
  }
}