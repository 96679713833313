import { Injectable } from '@angular/core';
import Question from '../models/Question';
import { FlowNode } from './flow-tree.service';
import CustomXmlPartContent from '../models/CustomXmlPartContent';
import Rule from '../models/rules/Rule';

@Injectable({
  providedIn: 'root'
})
export class WordService {
  private _propertiesCustomXmlPart?: Document;
  private _propertiesCustomXmlPartId?: string;
  private _rulesCustomXmlPartId?: string;
  private _properties: { [key: string]: string | undefined } = {};

  /**
   * @description adds a content control to the document which is used as a dictionary key
   * @param title title of the content control
   * @param text text of the content control
   * @param value value of the content control (in this case, the dictionary key)
   */
  addDictionaryKeyContentControl(title: string, text: string, value: string) {
    Word.run(async (context) => {
      const customXmlPartContent: CustomXmlPartContent = {
        type: 'dictionaryKey',
        key: value
      };
      const result = await this.addCustomXmlPart(context, customXmlPartContent);

      const selectionRange = context.document.getSelection();
      const contentControl = selectionRange.insertContentControl();
      contentControl.title = title;
      contentControl.insertText(`[${text}]`, Word.InsertLocation.end);
      contentControl.tag = `FF_${result.id}`;
      contentControl.appearance = 'BoundingBox';
      contentControl.getRange(Word.RangeLocation.after).select();
    }).then().catch(error => console.log(error));
  }

  /**
   * @description adds a table whith content controls to the document
   * @param question table question
   * @param node node of the question in the flow tree
   */
  async drawRepeatingTableContentControl(question: Question, node: FlowNode) {
    await Word.run(async (context) => {
      const selectionRange = context.document.getSelection();
      const table = selectionRange.insertTable(2, question.questions.length, Word.InsertLocation.before);

      for (let index = 0; index < question.questions.length; index++) {
        const subQuestion = question.questions[index];
        const subQuestionNode = node.items!
          .filter(n => n.label === "Subquestions")[0]
          .items!.filter(n => n.identifier!.endsWith(subQuestion.questionCode.replaceAll('.', '{FF_DOT}')))[0];

        const titleNode = subQuestionNode.items!.filter(n => n.label == "Title")[0];
        const titleCustomXmlPartContent: CustomXmlPartContent = {
          type: 'dictionaryKey',
          key: `${titleNode.identifier!}.questiontitle`
        };
        const titleCustomXmlPart = await this.addCustomXmlPart(context, titleCustomXmlPartContent)
        const headerCell = table.getCell(0, index);
        this.addContentControlToTableCell(headerCell, subQuestion, titleCustomXmlPart, "Title");

        const answerNode = subQuestionNode.items!.filter(n => n.label == "Answer")[0];
        const answerCustomXmlPartContent: CustomXmlPartContent = {
          type: 'dictionaryKey',
          key: `${answerNode.identifier!}.questionanswer`
        };
        const answerCustomXmlPart = await this.addCustomXmlPart(context, answerCustomXmlPartContent)
        const cell = table.getCell(1, index);
        this.addContentControlToTableCell(cell, subQuestion, answerCustomXmlPart, "Answer");
      }

      table.rows.load("items");
      await context.sync();
      table.rows.items[1].select();
      const selection = context.document.getSelection();
      const rowContentControl = selection.insertContentControl();
      rowContentControl.title = "Repeating Content Control"
      rowContentControl.tag = "FF_Table"

      await context.sync();
      table.getRange(Word.InsertLocation.after).select();
    });
  }

  /**
   * @description adds content controls to the document which is used as a repeating section
   * @param question table question
   * @param node node of the question in the flow tree
   */
  async drawRepeatingSectionContentControl(question: Question, node: FlowNode) {
    await Word.run(async (context) => {
      const range = context.document.getSelection();

      const sectionContentControl = range.insertContentControl();
      sectionContentControl.tag = "FF_Section";
      sectionContentControl.title = "Repeating content control";
      sectionContentControl.appearance = 'BoundingBox';

      for (const subQuestion of question.questions) {
        const subQuestionNode = node.items!
          .filter(n => n.label === "Subquestions")[0]
          .items!.filter(n => n.identifier!.endsWith(subQuestion.questionCode.replaceAll('.', '{FF_DOT}')))[0];

        const titleNode = subQuestionNode.items!.filter(n => n.label == "Title")[0];
        const titleCustomXmlPartContent: CustomXmlPartContent = {
          type: 'dictionaryKey',
          key: `${titleNode.identifier!}.questiontitle`
        };
        const titleCustomXmlPart = await this.addCustomXmlPart(context, titleCustomXmlPartContent);
        this.addContentControlToRepeatingSection(sectionContentControl, subQuestion, titleCustomXmlPart, "Title");

        const answerNode = subQuestionNode.items!.filter(n => n.label == "Answer")[0];
        const answerCustomXmlPartContent: CustomXmlPartContent = {
          type: 'dictionaryKey',
          key: `${answerNode.identifier!}.questionanswer`
        };
        const answerCustomXmlPart = await this.addCustomXmlPart(context, answerCustomXmlPartContent)
        this.addContentControlToRepeatingSection(sectionContentControl, subQuestion, answerCustomXmlPart, "Answer");
        sectionContentControl.insertBreak(Word.BreakType.line, Word.InsertLocation.end);
      }

      await context.sync();
      sectionContentControl.getRange(Word.InsertLocation.after).select();
    });
  }

  /**
   * @description adds a custom xml part to the document
   * @param context Word context
   * @param customXmlPartContent The content of the custom xml part 
   * @returns the created custom xml part
   */
  addCustomXmlPart = async (context: Word.RequestContext, customXmlPartContent: CustomXmlPartContent) => {
    const customXmlParts = context.document.customXmlParts;
    const customXmlPart = `<Ffx>${JSON.stringify(customXmlPartContent)}</Ffx>`;
    const result = customXmlParts.add(customXmlPart);
    await context.sync();
    return result;
  }

  /**
   * @description loads the content controls and custom xml parts from the document
   * @param context Word context
   * @returns loaded content controls and custom xml parts
   */
  async loadContentControlsAndCustomXmlParts(context: Word.RequestContext) {
    const contentControls = context.document.contentControls;
    const customXmlParts = context.document.customXmlParts;
    contentControls.load('tag');
    customXmlParts.load('id');
    await context.sync();

    return { contentControls, customXmlParts };
  }

  /**
   * @description gets the content of a custom xml part
   * @param customXmlParts a collection of custom xml parts
   * @param id id of the desired custom xml part
   * @param context Word context
   * @returns a custom xml part content object
   */
  async getCustomXmlpartContent(customXmlParts: Word.CustomXmlPartCollection, id: string, context: Word.RequestContext) {
    const customXmlPart = customXmlParts.getItem(id);
    const xml = customXmlPart.getXml();
    await context.sync();

    return JSON.parse(xml.value.replace(/<\/?[^>]+(>|$)/g, '')) as CustomXmlPartContent;
  }

  /**
   * @description Changes the color of a content control to red to indicate that it is invalid
   * @param contentControl a content control
   */
  markContentControlAsInvalid(contentControl: Word.ContentControl) {
    contentControl.font.color = "red";
  }

  /**
   * @description Tries to retrieve all FF document template rulesw from the document. If no rules are found, an empty array is returned.
   * @returns the rules from the document
   */
  async getRules() {
    let rules: Rule[] = [];
    try {
      rules = await this.retrieveRulesFromDocument();
    } catch {
      await this.addRulesToDocument(rules);
    }
    return rules;
  }

  /**
   * @description Adds a rule content control to the document
   * @param rule rule to add as a content control
   */
  async addRuleContentControl(rule: Rule) {
    await Word.run(async context => {
      const selectionRange = context.document.getSelection();
      const contentControl = selectionRange.insertContentControl();
      contentControl.appearance = "BoundingBox";
      contentControl.load("id");
      await context.sync();
      rule.contentControlId = contentControl.id.toString();
    });
  }

  /**
   * @ removes a rule content control from the document
   * @param rule rule to remove as content control
   */
  async removeRuleContentControl(rule: Rule) {
    await Word.run(async context => {
      const contentControls = await this.loadContentControls(context);
      const contentControl = contentControls.getByIdOrNullObject(Number(rule.contentControlId));

      if (contentControl) {
        contentControl.delete(true);
        await context.sync();
      }
    });
  }

  /**
   * @description highlights (basically just selects it) a rule content control in the document
   * @param rule rule to be highlighted
   */
  async highlightRuleContentControl(rule: Rule) {
    await Word.run(async context => {
      const contentControls = await this.loadContentControls(context);
      const contentControl = contentControls.getByIdOrNullObject(Number(rule.contentControlId));

      if (contentControl) {
        contentControl.select();
      }
    });
  }

  /**
   * @description saves the rules to the document
   * @param rules rules to be saved to the document
   */
  async updateRules(rules: Rule[]) {
    await Word.run(async (context) => {
      const customXmlParts = await this.loadCustomXmlParts(context);

      const customXmlPart = customXmlParts.getItem(this._rulesCustomXmlPartId!);
      customXmlPart.setXml(`<FF_Rules>${JSON.stringify(rules)}</FF_Rules>`);
      await context.sync();
      await this.renameRulesContentControls(rules);
    });
  }

  /**
   * @description gets a property from the document
   * @param propertyName property to get
   * @returns the property value
   */
  async getProperty(propertyName: string): Promise<string | undefined> {
    if (!this._propertiesCustomXmlPart) {
      await this.loadPropertiesCustomXmlPart();
      this.loadProperties();
    }

    return this._properties[propertyName];
  }

  /**
   * @description checks if the document is a valid document template
   * @returns true if yes, false if no
   */
  async IsValidDocumentTemplate(): Promise<boolean> {
    const tenantUrl = this.getProperty("_TenantUrl");
    const appUrl = await this.getProperty("_AppUrl");
    const flowId = await this.getProperty("_FlowId");

    return tenantUrl !== undefined && appUrl !== undefined && flowId !== undefined;
  }

  /**
   * @description loads the custom xml part containing all the the needed properties from the document
   */
  private async loadPropertiesCustomXmlPart() {
    await Word.run(async (context) => {
      const customXmlParts = context.document.customXmlParts;
      customXmlParts.load('items');
      await context.sync();

      for (const customXmlPart of customXmlParts.items) {
        const xmlData = customXmlPart.getXml();
        await context.sync();

        const parser = new DOMParser();
        let xmlDoc = parser.parseFromString(xmlData.value, 'application/xml');

        if (xmlDoc.querySelector("FF_Properties")) {
          this._propertiesCustomXmlPart = xmlDoc;
          this._propertiesCustomXmlPartId = customXmlPart.id;
          break;
        }

        if (!this._propertiesCustomXmlPartId
          && Office.context.platform == Office.PlatformType.OfficeOnline
          && xmlDoc.querySelector("Properties")
          && xmlDoc.querySelectorAll("property").length > 0) {
          xmlDoc = this.renameXmlTag(xmlDoc, "op:Properties", "FF_Properties");
          xmlDoc = this.renameXmlTag(xmlDoc, "Properties", "FF_Properties");
          xmlDoc = this.renameXmlTag(xmlDoc, "op:property", "Property");
          xmlDoc = this.renameXmlTag(xmlDoc, "property", "Property");

          this._propertiesCustomXmlPart = xmlDoc;
          this._propertiesCustomXmlPartId = customXmlPart.id;
          break;
        }
      }
    });
  }

  /**
   * @description renames the tags of an xml. This is needed because for some reason the tags in word online are a bit different than in the desktop version
   * @param xmlDoc the xml to be edited
   * @param oldTagName old name of the xml tag
   * @param newTagName new name of the xml tag
   * @returns xml document with renamed tag
   */
  private renameXmlTag(xmlDoc: Document, oldTagName: string, newTagName: string): Document {
    // Find all elements with the old tag name
    const elements = xmlDoc.getElementsByTagName(oldTagName);

    // Iterate over the elements and rename the tag
    for (let i = elements.length - 1; i >= 0; i--) {
      const element = elements[i];
      const newElement = xmlDoc.createElement(newTagName);

      // Copy attributes to the new element
      for (let j = 0; j < element.attributes.length; j++) {
        const attribute = element.attributes[j];
        newElement.setAttribute(attribute.name, attribute.value);
      }

      // Copy child nodes to the new element
      while (element.firstChild) {
        newElement.appendChild(element.firstChild);
      }

      // Replace the old element with the new element
      element.parentNode?.replaceChild(newElement, element);
    }

    return xmlDoc;
  }

  /**
   * @description loads the properties to a _properties variable from the properties custom xml part. The implementation depends on whether its word online or word desktop
   */
  private loadProperties() {
    if (this._propertiesCustomXmlPart) {
      const propertyElements = this._propertiesCustomXmlPart.querySelectorAll("Property");
      propertyElements.forEach(property => {
        if (Office.context.platform === Office.PlatformType.OfficeOnline) {
          const value = property.firstChild?.firstChild?.nodeValue ?? "";
          const name = property.getAttribute('name') ?? "";
          this._properties[name] = value;
        } else {
          const name = property.getAttribute("Name")!;
          const value = property.getAttribute("Value")!;
          this._properties[name] = value;
        }
      });
    }
  }

  /**
   * @description returns all custom xml parts of the document
   * @param context Word context
   * @returns all custom xml parts of the document
   */
  private async loadCustomXmlParts(context: Word.RequestContext) {
    const customXmlParts = context.document.customXmlParts;
    customXmlParts.load('id');
    await context.sync();
    return customXmlParts;
  }

  /**
   * @description loads all content controls of the document
   * @param context Word context
   * @returns all content controls of the document
   */
  private async loadContentControls(context: Word.RequestContext) {
    const contentControls = context.document.contentControls;
    contentControls.load('id');
    await context.sync();
    return contentControls;
  }

  /**
   * @description renames the content controls of the rules to match the rule index
   * @param rules rules to be renamed in content controls
   */
  private async renameRulesContentControls(rules: Rule[]) {
    await Word.run(async (context) => {
      const contentControls = await this.loadContentControls(context);
      rules.forEach((rule, index) => {
        const contentControl = contentControls.getByIdOrNullObject(Number(rule.contentControlId))
        if (contentControl) {
          contentControl.title = `Rule ${index + 1}`;
          contentControl.tag = `FF_Rule_${rule.contentControlId}`
        }
      });
      await context.sync();
    });
  }

  /**
   * @description retrieves the rules from the document custom xml part and sets the _rulesCustomXmlPartId
   * @returns the rules from the document custom xml part
   */
  private async retrieveRulesFromDocument(): Promise<Rule[]> {
    let rules: Rule[] = [];

    await Word.run(async (context) => {
      const customXmlParts = context.document.customXmlParts;
      customXmlParts.load('items');
      await context.sync();


      for (const customXmlPart of customXmlParts.items) {
        const xmlData = customXmlPart.getXml();
        await context.sync();

        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlData.value, 'application/xml');

        if (xmlDoc.querySelector("FF_Rules")) {
          const xmlValue = JSON.parse(xmlData.value.replace(/<\/?[^>]+(>|$)/g, ''));
          rules = xmlValue as Rule[];
          this._rulesCustomXmlPartId = customXmlPart.id;
          break;
        }
      }
    });

    if (!this._rulesCustomXmlPartId) {
      throw new Error("RulesCustomXmlPartId not found");
    }

    return rules;
  }

  /**
   * @description adds the rules to the document as a custom xml part
   * @param rules rules to be added to the document
   */
  private async addRulesToDocument(rules: Rule[]) {
    await Word.run(async (context) => {
      const customXmlParts = context.document.customXmlParts;
      const customXmlPart = `<FF_Rules>${JSON.stringify(rules)}</FF_Rules>`;
      customXmlParts.add(customXmlPart);
      await context.sync();
    });
  }

  /**
   * @description adds a content control to a table cell
   * @param cell cell of the table
   * @param subQuestion subquestion of the table
   * @param customXmlPart a customxmlpart to be refered to in the content control
   * @param title the title of the content control
   */
  private addContentControlToTableCell(cell: Word.TableCell, subQuestion: Question, customXmlPart: Word.CustomXmlPart, title: string) {
    const contentControl = cell.body.insertContentControl();
    contentControl.title = `Placeholder for ${title}`;
    contentControl.insertText(`[${subQuestion.title} ${title}]`, Word.InsertLocation.end);
    contentControl.tag = `FF_${customXmlPart.id}`;
    contentControl.appearance = 'BoundingBox';
  }

  /**
   * @description adds a content control to a repeating section content control
   * @param sectionContentControl the content control of the repeating section
   * @param subQuestion subQuestion of the table
   * @param customXmlPart a customxmlpart to be refered to in the content control
   * @param title the title of the content control
   */
  private addContentControlToRepeatingSection(sectionContentControl: Word.ContentControl, subQuestion: Question, customXmlPart: Word.CustomXmlPart, title: string) {
    const contentControl = sectionContentControl.insertText("", Word.InsertLocation.end).insertContentControl();
    contentControl.title = `Placeholder for ${title}`;
    contentControl.insertText(`[${subQuestion.title} ${title}]`, Word.InsertLocation.end);
    contentControl.tag = `FF_${customXmlPart.id}`;
    contentControl.appearance = 'BoundingBox';
  }
}
