import { Injectable } from '@angular/core';
import { toByteArray } from 'base64-js';
import { WordService } from './word.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _tenantName: string;
  private _tenantUrl: string;
  private _dialog: Office.Dialog;

  constructor(private _wordService: WordService) {}

  async authenticateUser(): Promise<void> {
    const token = localStorage.getItem('token');

    if (!token) {
      await this.getTenantNameAndUrl();
      return this.openLogin(this._tenantUrl, this._tenantName);
    }

    const expires = this.getTokenExpiryDate(token);
    if (expires < new Date()) {
      localStorage.removeItem('token');
      await this.getTenantNameAndUrl();
      return this.openLogin(this._tenantUrl, this._tenantName);
    }
  }

  async logoutUser(): Promise<void> {
    const token = localStorage.getItem('token');

    if (token) {
      localStorage.removeItem('token');
      return this.openLogout();
    } else {
      console.log('Not logged in, missing token.');

      return Promise.resolve();
    }
  }

  private openLogout(): Promise<void> {
    return new Promise<void>((resolve) => {
      Office.context.ui.displayDialogAsync(
        `${window.location.origin}/logout`,
        { height: 65, width: 30 },
        (asyncResult) => {
          this._dialog = asyncResult.value;
          this._dialog.addEventHandler(
            Office.EventType.DialogMessageReceived,
            () => {
              this._dialog.close();
              resolve();
            }
          );
        }
      );
    });
  }

  private openLogin(tenantUrl: string, tenantName: string): Promise<void> {
    return new Promise<void>((resolve) => {
      Office.context.ui.displayDialogAsync(
        `${window.location.origin}/login?tenantUrl=${tenantUrl}&tenantName=${tenantName}`,
        { height: 65, width: 30 },
        (asyncResult) => {
          this._dialog = asyncResult.value;
          this._dialog.addEventHandler(
            Office.EventType.DialogMessageReceived,
            (arg: any) => {
              this.closeDialog(arg);
              resolve();
            }
          );
        }
      );
    });
  }
  private closeDialog(arg: any): void {
    this._dialog.close();
    localStorage.setItem('token', arg.message);
  }

  private async getTenantNameAndUrl(): Promise<void> {
    const tenantUrl = await this._wordService.getProperty("_TenantUrl");
    const tenantName = tenantUrl?.replace("https://", "").replace(".sharepoint.com", "") + ".onmicrosoft.com";
    this._tenantName = tenantName;
    this._tenantUrl = tenantUrl ?? "";
  }

  private getTokenExpiryDate(token: string): Date {
    const payload = token.split('.')[1];
    const padding = '='.repeat((4 - (payload.length % 4)) % 4);
    const base64 = payload + padding;
    const decodedToken = JSON.parse(
      new TextDecoder().decode(toByteArray(base64))
    );
    const expires = new Date(decodedToken.exp * 1000);
    return expires;
  }
}
